import { AppBar, Toolbar, Typography, Button, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMsal, UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalInstance, msalConfig } from './Auth';

export const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isOnHomePage = (pathname == '/');
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  const upload = base + '/#/upload';
  const { accounts } = useMsal();
  const doLogin = () => {
    msalInstance.loginRedirect(msalConfig);
  };
  const doLogout = () => {
    msalInstance.logoutRedirect({account: accounts[0], ...msalConfig});
  };
  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar>
        <Link href={home} color="inherit" underline="none">
          <Typography variant="h6" component="div" color="#009FE3">
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '5px',
                paddingRight: '8px',
                paddingBottom: '4px',
                width: '150px',
                height: '40px',
              }}
              component="img"
              height="40"
              alt=""
              src="cyanconnode.svg"
            />
            Secure Secret Sharing
          </Typography>
        </Link>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <UnauthenticatedTemplate>
            <Button
              component={Link}
              onClick={doLogin}
              variant="contained"
              color="primary"
            >
              {t('header.buttonLogin')}
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Typography display="inline" sx={{ marginRight: '8px' }} variant="body2" color="textSecondary" fontSize="0.75rem">{accounts[0]?.username}</Typography>
            <Button
              component={Link}
              onClick={doLogout}
              variant="contained"
              color="primary"
            >
              {t('header.buttonLogout')}
            </Button>
            <Button
              component={Link}
              sx={{ marginLeft: '8px' }}
              href={isOnHomePage ? upload : home}
              variant="contained"
              color="primary"
            >
              {isOnHomePage ? t('header.buttonUpload') : t('header.buttonHome')}
            </Button>
          </AuthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
