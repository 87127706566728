import { PublicClientApplication } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';

export const msalConfig = {
  auth: {
    clientId: "32f52178-242a-4f77-b58f-0ee325918c5e",
    authority: "https://login.microsoftonline.com/ec7fe84f-862b-4912-9687-ef87d7fec414",
    redirectUri: "https://sss.cyanconnode.com",
    postLogoutRedirectUri: "https://sss.cyanconnode.com"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
