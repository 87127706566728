import { AuthenticatedTemplate } from '@azure/msal-react';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { encrypt, createMessage } from 'openpgp';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  OneTime,
  SpecifyPasswordToggle,
  SpecifyPasswordInput,
} from './CreateSecret';
import Error from '../shared/Error';
import Expiration from './../shared/Expiration';
import Result from '../displaySecret/Result';
import { randomString, uploadFile } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { msalInstance } from '../shared/Auth';

const Upload = () => {
  const maxSize = 1024 * 500;
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [result, setResult] = useState({
    password: '',
    customPassword: false,
    uuid: '',
  });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      generateDecryptionKey: true,
      secret: '',
      password: '',
      expiration: '3600',
      onetime: true,
    },
  });

  const form = watch();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        handleSubmit(onSubmit)();

        // get access token
        const accessTokenResponse = await msalInstance.acquireTokenSilent({scopes: ["api://32f52178-242a-4f77-b58f-0ee325918c5e/uploadFile"], account: msalInstance.getAllAccounts()[0]});

        const pw = form.password ? form.password : randomString();
        const message = await encrypt({
          format: 'armored',
          message: await createMessage({
            binary: new Uint8Array(reader.result as ArrayBuffer),
            filename: acceptedFiles[0].name,
          }),
          passwords: pw,
        });
        const { data, status } = await uploadFile({
          expiration: parseInt(form.expiration),
          message,
          one_time: form.onetime,
        }, accessTokenResponse.accessToken);

        if (status !== 200) {
          setError(data.message);
        } else {
          setResult({
            uuid: data.message,
            password: pw,
            customPassword: form.password ? true : false,
          });
        }
      };
      acceptedFiles.forEach((file) => reader.readAsArrayBuffer(file));
    },
    [form, handleSubmit],
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      maxSize,
      minSize: 0,
      onDrop,
    });

  const onSubmit = () => {};

  const isFileTooLarge =
    fileRejections.length > 0 &&
    fileRejections[0].errors[0].code === 'file-too-large';

  const generateDecryptionKey = watch('generateDecryptionKey');

  if (result.uuid) {
    return (
      <AuthenticatedTemplate>
        <Result
          uuid={result.uuid}
          password={result.password}
          prefix="f"
          customPassword={result.customPassword}
        />
      </AuthenticatedTemplate>
    );
  }
  return (
    <AuthenticatedTemplate>
      <Grid>
        {isFileTooLarge && <Error message={t('upload.fileTooLarge')} />}
        <Error message={error} onClick={() => setError('')} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Grid container justifyContent="center">
              <Typography variant="h4">{t('upload.title')}</Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography variant="caption" display="block">
                {t('upload.caption')}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <FontAwesomeIcon
                color={isDragActive ? 'blue' : 'black'}
                size="8x"
                icon={faFileUpload}
              />
            </Grid>
          </div>

          <Grid container justifyContent="center" mt="15px">
            <Expiration control={control} />
          </Grid>
          <Grid container alignItems="center" direction="column">
            <OneTime control={control} />
            <SpecifyPasswordToggle control={control} />
            <Grid container justifyContent="center">
              {!generateDecryptionKey && (
                <SpecifyPasswordInput control={control} />
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </AuthenticatedTemplate>
  );
};

export default Upload;
